// React / Redux
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

// Components JSX
import Slider from 'react-slick';
import { useGetMarqueesQuery } from '../../data/api/jet-engine-api';
import MarqueeCard from './components/marquee-card';
import { useQuoteToolContext } from '../../quote-tool';
import SvgIcon from '../../common/svg-icon';
import Basket from '../../common/basket/basket';

// Constants
const DEFAULT_SLICK_SETTINGS = {
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    lazyLoad: true,
    responsive: [
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 1,
                centerMode: false
            }
        }
    ]
};

export default function StepTwo() {
    const [activeSlide, setActiveSlide] = useState(0);
    const [infoWindow, setInfoWindow] = useState(null);

    const sliderMarqueesRef = useRef();

    const {
        setNextDisabled,
        quoteToolConfig: {
            step_two_heading: stepTwoHeading,
            step_two_description: stepTwoDescription,
            max_capacity_message: redirectMessage
        }
    } = useQuoteToolContext();

    const {
        EVENT_DETAILS: { PARTY_SEAT_STAND, PARTY_GUESTS },
        MARQUEE
    } = useSelector((store) => store.quoteDetails);

    useEffect(() => {
        setNextDisabled(!MARQUEE);

        return () => setNextDisabled(true);
    }, [setNextDisabled, MARQUEE]);

    const { data: marqueesQuery, isLoading: marqueesLoading } =
        useGetMarqueesQuery();

    const marquees = useMemo(
        () =>
            marqueesQuery?.slice().sort((a, b) => {
                const sortKey = PARTY_SEAT_STAND.toLowerCase();

                return parseInt(a[sortKey]) - parseInt(b[sortKey]);
            }),
        [PARTY_SEAT_STAND, marqueesQuery]
    );

    const reccomendedMarquee = useMemo(() => {
        if (!marquees) {
            return;
        }

        const partySeatStand = PARTY_SEAT_STAND.toLowerCase();
        const partyGuests = parseInt(PARTY_GUESTS);

        const eligibleMarquees = marquees?.filter((marquee) => {
            return partyGuests <= marquee[partySeatStand];
        });

        if (eligibleMarquees.length === 0) {
            return null;
        }

        let closestMarquee = eligibleMarquees[0];
        let minDifference = Math.abs(
            eligibleMarquees[0][partySeatStand] - partyGuests
        );

        eligibleMarquees?.forEach((marquee) => {
            const difference = Math.abs(marquee[partySeatStand] - partyGuests);

            if (difference < minDifference) {
                closestMarquee = marquee;
                minDifference = difference;
            }
        });

        return closestMarquee;
    }, [PARTY_GUESTS, PARTY_SEAT_STAND, marquees]);

    useEffect(() => {
        if (!marquees) {
            return;
        }

        if (MARQUEE) {
            setActiveSlide(
                marquees.findIndex((marquee) => marquee.id === MARQUEE.id)
            );
        } else if (reccomendedMarquee) {
            setActiveSlide(
                marquees.findIndex(
                    (marquee) => marquee.id === reccomendedMarquee.id
                )
            );
        }
    }, [MARQUEE, marquees, reccomendedMarquee]);

    useEffect(() => {
        if (activeSlide) {
            const sliderElement = sliderMarqueesRef?.current;

            setTimeout(() => sliderElement?.slickGoTo(activeSlide), 500);
        }
    }, [activeSlide]);

    const slickSettings = useMemo(
        () => ({
            ...DEFAULT_SLICK_SETTINGS,
            initialSlide: activeSlide || 0,
            afterChange: (slide) => {
                setActiveSlide(slide);
                setInfoWindow(null);
            }
        }),
        [activeSlide]
    );

    return (
        // Make phase styles common
        <div className="step-two">
            <div className="phase phase--header">
                <div className="phase__item">
                    <div className="phase__header">
                        <h2>{stepTwoHeading}</h2>

                        {reccomendedMarquee && (
                            <p>
                                {stepTwoDescription.replace(
                                    '{MARQUEE}',
                                    reccomendedMarquee.name
                                )}
                            </p>
                        )}
                    </div>

                    {MARQUEE && <Basket cssClass="step-two__basket" />}
                </div>
            </div>

            {reccomendedMarquee ? (
                <div className="phase phase--marquee-list">
                    <div className="phase__item">
                        <div className="step-two__marquee-list">
                            {infoWindow ? (
                                <div className="step-two__marquee-info">
                                    <button
                                        className="step-two__marquee-info-close"
                                        onClick={() => setInfoWindow(null)}
                                    >
                                        <SvgIcon
                                            name="close"
                                            cssClass="step-two__marquee-info-close-icon"
                                        />
                                    </button>

                                    <div
                                        className="step-two__marquee-info-content"
                                        dangerouslySetInnerHTML={{
                                            __html: infoWindow
                                        }}
                                    />
                                </div>
                            ) : (
                                marquees && (
                                    <div className="step-two__selected-marquee-img">
                                        <img
                                            src={marquees[activeSlide]?.image}
                                            alt={marquees[activeSlide]?.name}
                                        />
                                    </div>
                                )
                            )}

                            <div className="slider-container">
                                <Slider
                                    ref={sliderMarqueesRef}
                                    {...slickSettings}
                                >
                                    {marquees?.map((marquee) => (
                                        <div
                                            key={marquee.id}
                                            className="slider-item"
                                        >
                                            <MarqueeCard
                                                marquee={marquee}
                                                setInfoWindow={setInfoWindow}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                !marqueesLoading && (
                    <div
                        className="step-two__redirect"
                        dangerouslySetInnerHTML={{
                            __html: redirectMessage
                        }}
                    />
                )
            )}
        </div>
    );
}
